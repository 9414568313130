import React from "react"
// import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

// s4n
import MenuUtils from "./../utils"
// import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'

import TemplateStandard from './Template/standard'
import TemplateGoto from './Template/goto'
import TemplateService from './Template/service'



const ComponentStateless = (props) => {
    const intl = useIntl();
    // const t = intl.formatMessage;

    const nid = props.nid || null;

    if (null === nid) {
        return null;
    }

    const template = props.template || null;
    const exclude = props.exclude || []; // exclude globally a page ex. about-us/news
    const aNodeMenuItemSubpages = MenuUtils.getSubpagesByNodeId(nid, intl.locale, exclude); // displaySectionShortDescriptionForParentNodeId={[1054]}

    if (typeof aNodeMenuItemSubpages === `undefined`) {
        return null;
    }

    if (`goto` === template) {
        return <TemplateGoto {...props} />
    }

    if (`service` === template) {
        return <TemplateService {...props} />
    }

    return (
        <TemplateStandard {...props} />
    )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default ComponentStateless