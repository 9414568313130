import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import MenuUtils from "./../../utils"
import Page from "./../../../../../model/node/page"
import Link from "./../../../Link"
import kebabCase from "lodash.kebabcase"

const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const nid = props.nid || null;
    const exclude = props.exclude || [];
    const displaySectionShortDescription = (props.displaySectionShortDescriptionForParentNodeId && props.displaySectionShortDescriptionForParentNodeId.includes(nid)) || false;
    const aNodeMenuItemSubpages = MenuUtils.getSubpagesByNodeId(nid, intl.locale, exclude);

    const disableHeader = props.disableHeader || false;
    const nodeService = Page.getItemByNodeId(nid, intl.locale);

    const mapServiceNid2Icon = {
      18: <i className="fas fa-desktop"></i>,           // 18 Strony internetowe
      19: <i className="fas fa-shopping-cart"></i>,     // 19 Sklepy internetowe
      20: <i className="fas fa-rocket"></i>,            // 20 Aplikacje dedykowane
      21: <i className="fas fa-mobile-alt"></i>,        // 21 Aplikacje mobilne
      22: <i className="fas fa-server"></i>,            // 22 Hosting i serwery dedykowane
      23: <i className="fas fa-chart-line"></i>,        // 23 Analiza i consulting
      24: <i className="fas fa-cogs"></i>,              // 24 Outsourcing
      25: <i className="fas fa-search-dollar"></i>,     // 25 Marketing i SEO
      26: <i className="far fa-window-restore"></i>,    // 26 Testowanie i wsparcie
      27: <i className="fas fa-drafting-compass"></i>,  // 27 Grafika i design
    }

    return (
        <React.Fragment>
          <section className="section position-relative s4n-education">
            <div className="container">
              
              {!disableHeader && 
                <div className="row">
                  <div className="col text-center">
                    <h2 className="section-title">
                      {/* <Link to={serviceNodeId}></Link> */}
                      {nodeService ? nodeService.title : null}
                    </h2>
                  </div>
                </div>
              }

              <div className="row justify-content-center">
                {aNodeMenuItemSubpages.map(({ node: nodeMenuItem }, index) => {
                  const nid = MenuUtils.getNodeIdByItem(nodeMenuItem);
                  const nodePage = Page.getItemByNodeId(nid, intl.locale);

                  return (
                      //     {nodePage && nodePage.field_summary && <p dangerouslySetInnerHTML={{ __html: nodePage.field_summary }}></p>}
                      //     {/* <p>{nodePage.body}</p> */}
                      //     {/* {nodeBodyField} */}
                      //     <p><Link to={nid} className="btn btn-secondary goto-scroll" role="button">{ t({ id: "soft4net_more" }) }</Link></p>


                      <div key={`services_item_${index}`} className="col-lg-4 col-md-6 mb-5">
                          <div className={`d-flex ${kebabCase(nodePage.title)} nid-${nid}`}>
                              <div className="icon">
                                  {/* <img src="images/icons/plan.png" className="mb-4" alt="icon" /> */}
                                  <Link to={nid} title={nodePage.title}>
                                    {typeof mapServiceNid2Icon[nid] !== `undefined` ? mapServiceNid2Icon[nid] : <i className="fas fa-user-shield"></i>}
                                  </Link>
                              </div>
                              <div>
                                  <h3 className="mb-0"><Link to={nid} title={nodePage.title}></Link></h3>
                                  {/* <p className="mb-0 _text-light">Bla bla bla</p> */}
                                  {nodePage && nodePage.field_summary && 
                                    <p className="mb-0 _text-light" dangerouslySetInnerHTML={{ __html: nodePage.field_summary }}></p>
                                  }
                              </div>
                          </div>
                      </div>

                      // <div key={`services_item_${index}`} className="col-lg-3 _col-md-4 text-center hover-shadow     d-flex justify-content-center align-items-center">
                      //   <div className={`${kebabCase(nodePage.title)}`}>
                      //     {/* <img src="images/icons/plan.png" className="mb-4" alt="icon" /> */}
                      //     {/* <i className="fas fa-user-shield mb-4"></i> */}
                      //     <h4 className="mb-0 py-3"><Link to={nid}></Link></h4>
                      //     {nodePage && nodePage.field_summary && <p dangerouslySetInnerHTML={{ __html: nodePage.field_summary }}></p>}
                      //   </div>
                      // </div>
                  )
                })}
              </div>

              {!disableHeader && 
                  <div className="row">
                      <div className="col-12 text-center">
                          <Link to={nid} className="btn btn-primary">{intl.locale === `en` ? `More services` : `Więcej usług`}</Link>
                      </div>
                  </div>
              }

            </div>
          </section>
        </React.Fragment>
      )
}

export default ComponentStateless